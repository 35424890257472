<template>
  <div>
    <v-layout wrap>
      <v-flex>
        <v-layout wrap>
          <v-flex xs12>
            <v-layout
              wrap
              justify-center
              class="hidden-sm-and-down"
              mb-4
              style="
                height: 90px;
                box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.08);
              "
            >
              <v-flex xs4 pl-10 pt-4 pb-4>
                <v-layout wrap justify-start pl-10 fill-height>
                  <v-flex xs1 align-self-center>
                    <v-icon color="green">mdi-phone-plus-outline</v-icon>
                  </v-flex>
                  <v-flex xs3 text-left align-self-center>
                    <v-layout wrap>
                      <v-flex
                        xs12
                        style="
                          font-family: opensansregular;
                          font-size: 14px;
                          color: #000;
                        "
                        >Call Us</v-flex
                      >
                      <v-flex
                        xs12
                        style="
                          font-family: opensansregular;
                          font-size: 14px;
                          color: #000;
                          font-weight: bold;
                        "
                      >
                        +91&nbsp;7510255897
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>

              <v-flex xs4 class="fixItem">
                <v-layout wrap justify-center fill-height>
                  <v-flex
                    style="cursor: pointer"
                    @click="$router.push('/LoginPage')"
                    text-center
                    xs12
                    align-self-center
                  >
                    <span
                      style="
                        font-family: brightfont;
                        font-size: 18px;
                        color: #000;
                        font-weight: lighter;
                      "
                    >
                      Story of 1000 planters
                    </span>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs4>
                <v-layout wrap fill-height>
                  <v-flex xs12 align-self-center>
                    <v-layout pl-14 wrap justify-center>
                      <v-flex
                        pt-4
                        style="cursor: pointer"
                        @click="$router.push('/LoginPage')"
                        xs1
                        pt-3
                      >
                        <v-icon size="160%" color="black"
                          >mdi-bookmark-outline</v-icon
                        >
                      </v-flex>

                      <v-flex
                        pt-4
                        style="cursor: pointer"
                        @click="$router.push('/LoginPage')"
                        xs1
                        pt-3
                      >
                        <v-icon size="160%" color="black"
                          >mdi-cart-outline</v-icon
                        >
                      </v-flex>
                      <v-flex
                        style="cursor: pointer"
                        @click="$router.push('/LoginPage')"
                        xs2
                        pt-3
                      >
                        <v-btn color="#13736f">
                          <span
                            style="color: white; font-family: dmsansregular"
                          >
                            LOGIN
                          </span>
                        </v-btn>
                      </v-flex>
                      <v-flex
                        style="cursor: pointer"
                        @click="$router.push('/Registration')"
                        xs2
                        pl-4
                        pt-3
                      >
                        <v-btn color="#13736f">
                          <span
                            style="color: white; font-family: dmsansregular"
                          >
                            REGISTER
                          </span>
                        </v-btn>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-4>
              <v-flex
                data-aos="zoom-in-up"
                data-aos-ease="ease"
                data-aos-duration="1500"
                data-aos-delay="1000"
                xs11
                lg10
              >
                <v-card class="mainbg4" style="border-radius: 27px">
                  <v-layout justify-start wrap>
                    <v-flex xs8>
                      <v-img
                        contain
                        src="../../assets/Images/pattern1.2.png"
                        style="border-radius: 27px"
                      ></v-img
                    ></v-flex>
                    <v-flex xs12 lg6 pt-6>
                      <v-layout wrap justify-center>
                        <v-flex
                          lg8
                          xs11
                          :class="{ 'text-left': $vuetify.breakpoint.mdAndUp }"
                        >
                          <span
                            style="
                              font-family: opensansregular;
                              font-size: 16px;
                              color: #185a57;
                              font-weight: bold;
                            "
                            >STORY OF 1000 PLANTERS
                          </span>
                        </v-flex>
                        <v-flex
                          xs11
                          lg9
                          :class="{ 'pl-6': $vuetify.breakpoint.mdAndUp }"
                          class="text-left"
                        >
                          <span
                            style="
                              font-family: archivoregular;
                              font-size: 35px;
                              color: #000;
                              font-weight: 900;
                            "
                            >The Spice Emporium Find the Freshest spices in One
                            Place
                          </span>
                        </v-flex>
                        <v-flex
                          xs11
                          lg9
                          pt-1
                          :class="{ 'pl-4': $vuetify.breakpoint.mdAndUp }"
                          class="pl-6 text-left"
                        >
                          <span
                            style="
                              font-family: archivoregular;
                              font-size: 18px;
                              color: #645e5e;
                            "
                            >Experience the Finest Selection of Cardamom and
                            Pepper Spices
                          </span>
                        </v-flex>
                        <v-flex xs9 pl-6 pt-4>
                          <v-btn
                            style="border-radius: 7px"
                            height="44px"
                            width="205px"
                            class="no-uppercase"
                            dark
                            color="#13736f"
                          >
                            <span
                              style="
                                font-family: dmsansregular;
                                font-size: 16px;
                                color: white;
                                letter-spacing: 1px;
                                cursor: pointer;
                              "
                            >
                              <span>Learn More</span>
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs11 lg4>
                      <v-img
                        height="100%"
                        width="100%"
                        src="../../assets/Images/homeimage3.png"
                      ></v-img>
                    </v-flex>
                    <v-flex xs8 pt-2>
                      <div style="padding-top: 3px">
                        <v-img
                          contain
                          src="../../assets/Images/pattern2.3.png"
                          style="border-radius: 27px"
                        ></v-img>
                      </div>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>

            <v-layout pa-3 wrap justify-center>
              <v-flex
                xs10
                pr-6
                pt-4
                text-center
                style="
                  font-family: opensansregular;
                  color: black;
                  font-size: 30px;
                  font-weight: bold;
                "
                >Shop by spice Type</v-flex
              >

              <v-flex xs11 style="cursor: pointer">
                <v-layout
                  wrap
                  :class="{
                    '': $vuetify.breakpoint.mdAndUp,
                    '': $vuetify.breakpoint.mdAndUp,
                  }"
                  justify-center
                >
                  <v-flex
                    data-aos="zoom-in-down"
                    data-aos-ease="ease"
                    data-aos-duration="1500"
                    data-aos-delay="200"
                    xs12
                    sm6
                    lg4
                    xl3
                    @click="getListByCategory(item._id)"
                    v-for="(item, i) in category"
                    :key="i"
                    class="image-container"
                  >
                    <v-layout wrap justify-center>
                      <v-flex xs9 pt-6>
                        <v-img
                          height="215px"
                          width="350px"
                          style="border-radius: 20px"
                          :src="mediaURLnew + item.icon"
                        >
                          <v-layout wrap justify-center fill-height>
                            <v-flex
                              xs12
                              text-center
                              align-self-center
                              style="
                                font-family: opensansregular;
                                color: white;
                                font-size: 30px;
                                font-weight: bold;
                              "
                              >{{ item.name }}</v-flex
                            >
                          </v-layout>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <v-layout wrap justify-center pt-2 pl-xs-0>
              <v-flex xs12>
                <v-layout wrap justify-center>
                  <v-flex
                    data-aos="zoom-in-up"
                    data-aos-ease="ease"
                    data-aos-duration="1500"
                    data-aos-delay="500"
                    class="hover-effect"
                    xs8
                    sm5
                    lg2
                    pl-4
                    pt-6
                    v-for="(item, i) in list"
                    :key="i"
                  >
                    <v-card elevation="0" outlined>
                      <v-layout wrap></v-layout>
                      <v-layout wrap justify-center pa-3>
                        <center>
                          <v-flex
                            class="hidden-sm-and-down"
                            pl-8
                            xs4
                            style="cursor: pointer"
                          >
                            <v-avatar size="90px">
                              <v-img
                                :src="mediaURLnew + item.photos[0]"
                              ></v-img>
                            </v-avatar>
                          </v-flex>
                        </center>
                        <v-flex
                          class="hidden-md-and-up"
                          pl-7
                          xs4
                          style="cursor: pointer"
                        >
                          <v-avatar size="90px">
                            <v-img :src="mediaURLnew + item.photos[0]"></v-img>
                          </v-avatar>
                        </v-flex>
                        <v-flex
                          v-if="!item.inWishlist"
                          xs5
                          text-right
                          @click="$router.push('/LoginPage')"
                          style="cursor: pointer"
                        >
                          <v-icon color="black" size="200%"
                            >mdi-bookmark-outline</v-icon
                          >
                        </v-flex>
                        <v-flex
                          style="cursor: pointer"
                          v-else
                          xs5
                          text-right
                          @click="addtoWishlist(item._id)"
                        >
                          <v-icon color="#E96125" size="200%"
                            >mdi-bookmark</v-icon
                          >
                        </v-flex>

                        <v-flex
                          @click="$router.push('/LoginPage')"
                          pt-3
                          text-center
                          xs12
                          style="
                            color: #bcb4b4;
                            cursor: pointer;
                            font-size: 12px;
                          "
                          class="opensansregular"
                        >
                          {{ item.categoryId.name }}
                        </v-flex>

                        <v-flex
                          pt-1
                          text-center
                          xs12
                          class="dmsansregular"
                          style="
                            color: black;
                            font-size: 20px;
                            cursor: pointer;
                            font-weight: 500;
                          "
                        >
                          {{ item.name }}
                        </v-flex>

                        <v-flex
                          @click="$router.push('/products?id=' + item._id)"
                          pt-1
                          text-center
                          xs12
                          class="dmsansregular"
                          style="
                            color: black;
                            cursor: pointer;
                            font-size: 14px;
                            font-weight: 600;
                          "
                        >
                          {{ item.weight }} {{ item.weightType }}
                        </v-flex>
                        <v-flex
                          pt-1
                          text-center
                          xs12
                          class="dmsansregular"
                          style="
                            color: #29807c;
                            font-size: 20px;
                            font-weight: 700;
                          "
                        >
                          Rs {{ item.price }}
                        </v-flex>
                        <v-flex v-if="!item.inCart" pt-1 text-center xs12>
                          <v-btn
                            v-if="item.stock > 0"
                            @click="$router.push('/LoginPage')"
                            class="no-uppercase"
                            width="110px"
                            color="#13736f"
                            ><span
                              style="
                                font-family: opensansregular;
                                font-size: 14px;
                                color: #ffffff;
                                letter-spacing: 1px;
                                cursor: pointer;
                              "
                              >&nbsp;Add to Cart &nbsp;
                            </span>
                          </v-btn>
                          <v-btn
                            v-else
                            disabled
                            class="no-uppercase"
                            width="110px"
                            color="#13736f"
                            ><span
                              style="
                                font-family: opensansregular;
                                font-size: 14px;
                                color: red;
                                letter-spacing: 1px;
                                cursor: pointer;
                              "
                              >&nbsp;Out of Stock &nbsp;
                            </span>
                          </v-btn>
                        </v-flex>

                        <v-flex v-else pt-1 text-center xs12>
                          <v-btn
                            v-if="item.stock > 0"
                            @click="$router.push('/cart')"
                            class="no-uppercase"
                            width="110px"
                            color="#13736f"
                            ><span
                              style="
                                font-family: opensansregular;
                                font-size: 14px;
                                color: #ffffff;
                                letter-spacing: 1px;
                                cursor: pointer;
                              "
                              >&nbsp;Go to Cart &nbsp;
                            </span>
                          </v-btn>
                          <v-btn
                            v-else
                            disabled
                            class="no-uppercase"
                            width="110px"
                            color="#13736f"
                            ><span
                              style="
                                font-family: opensansregular;
                                font-size: 14px;
                                color: red;
                                letter-spacing: 1px;
                                cursor: pointer;
                              "
                              >&nbsp;Out of Stock &nbsp;
                            </span>
                          </v-btn>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
            <v-layout pt-9 pb-6 wrap justify-center>
              <v-flex xs3 align-self-center>
                <v-pagination
                  prev-icon="mdi-menu-left"
                  next-icon="mdi-menu-right"
                  :length="pages"
                  :total-visible="7"
                  v-model="currentPage"
                  color="#13736f"
                ></v-pagination>
              </v-flex>
            </v-layout>
            <v-layout wrap justify-center pt-4>
              <v-flex xs11 lg10>
                <v-card
                  style="border-radius: 27px"
                  class="hidden-sm-and-down bg10"
                >
                  <v-layout justify-center center fill-height>
                    <v-flex
                      data-aos="slide-right"
                      data-aos-ease="ease"
                      data-aos-duration="1500"
                      data-aos-delay="500"
                      xs
                      lg4
                    >
                      <v-img src="../../assets/Images/banner1.png"></v-img>
                    </v-flex>
                    <v-flex
                      xs11
                      data-aos="zoom-in-left"
                      data-aos-ease="ease"
                      data-aos-duration="1500"
                      data-aos-delay="500"
                      lg6
                      align-self-center
                    >
                      <v-layout wrap justify-center>
                        <v-flex
                          xs12
                          text-center
                          style="
                            font-family: dmsansregular;
                            color: white;
                            font-size: 30px;
                          "
                        >
                          Experience Seamless Convenience
                        </v-flex>
                        <v-flex
                          xs9
                          text-center
                          class="dmsansregular"
                          style="color: white; font-size: 40px"
                        >
                          Download Our App Now !
                        </v-flex>
                        <v-flex xs4 pt-3>
                          <v-img
                            contain
                            src="../../assets/Images/playstore.png"
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
                <v-card
                  class="hidden-lg-and-up bg10"
                  style="border-radius: 27px"
                >
                  <v-layout xs12 wrap justify-center center fill-height>
                    <v-flex xs12>
                      <v-img src="../../assets/Images/banner1.png"></v-img>
                    </v-flex>
                    <v-flex xs12 align-self-center>
                      <v-layout wrap justify-center>
                        <v-flex
                          pt-3
                          xs12
                          text-center
                          style="color: white; font-size: 23px"
                        >
                          Experience Seamless Convenience
                        </v-flex>
                        <v-flex
                          xs12
                          text-center
                          style="color: white; font-size: 40px"
                        >
                          Download Our App Now !
                        </v-flex>
                        <v-flex xs12 pa-3>
                          <v-img
                            contain
                            src="../../assets/Images/playstore.png"
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex
                xs12
                pt-8
                data-aos="zoom-in-up"
                data-aos-ease="ease"
                data-aos-duration="1500"
                data-aos-delay="1000"
              >
                <v-img src="../../assets/Images/homeimage5.png">
                  <v-layout wrap justify-center align-center fill-height>
                    <v-flex xs12 lg7 align-self-center>
                      <v-layout wrap justify-center>
                        <v-flex
                          data-aos="slide-up"
                          data-aos-ease="ease"
                          data-aos-duration="1500"
                          data-aos-delay="1000"
                          xs10
                          text-center
                          style="
                            font-family: opensansregular;
                            color: white;
                            font-size: 40px;
                            font-weight: bold;
                          "
                          >Discover a World of Flavors</v-flex
                        >
                        <v-flex
                          data-aos="slide-up"
                          data-aos-ease="ease"
                          data-aos-duration="1500"
                          data-aos-delay="1000"
                          pt-5
                          xs12
                          text-center
                          style="
                            font-family: opensansregular;
                            color: white;
                            font-size: 25px;
                          "
                          >Unleash Culinary Excellence with Our Premium Spices
                          and Herbs. Shop Now for Exceptional Taste and
                          Aroma!</v-flex
                        >
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-img>
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12>
                <v-layout wrap class="hidden-sm-and-down">
                  <v-flex xs12>
                    <v-layout
                      fill-height
                      justify-center
                      wrap
                      style="
                        background-color: rgba(38, 38, 38, 1);
                        height: 250px;
                      "
                    >
                      <v-flex xs4 align-self-center>
                        <v-layout wrap justify-center fill-height>
                          <v-flex
                            xs8
                            align-self-center
                            style="
                              font-family: brightfont;
                              font-size: 18px;
                              color: white;
                              font-weight: 400px;
                            "
                            >Story of 1000 planters
                          </v-flex>
                          <v-flex
                            pl-5
                            pt-7
                            xs9
                            align-self-center
                            style="
                              font-family: dmsansregular;
                              font-size: 16px;
                              color: white;
                              font-weight: 400;
                            "
                            >Your go-to online spice emporium, delivering the
                            finest and freshest spices to elevate every dish and
                            inspire your culinary journey.
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs8>
                        <v-layout wrap fill-height>
                          <v-flex xs12 align-self-center>
                            <v-layout wrap justify-center>
                              <v-flex xs3 pt-8>
                                <v-layout wrap>
                                  <v-flex
                                    xs12
                                    pb-5
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                    "
                                    >Shopping cart</v-flex
                                  >
                                  <v-flex
                                    xs12
                                    pb-5
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                    "
                                    >Account</v-flex
                                  >

                                  <v-flex
                                    @click="$router.push('/Registration')"
                                    xs12
                                    pb-5
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                      cursor: pointer;
                                    "
                                    >Signup</v-flex
                                  >
                                  <v-flex
                                    @click="$router.push('/LoginPage')"
                                    xs12
                                    pb-5
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                      cursor: pointer;
                                    "
                                    >Login</v-flex
                                  >
                                </v-layout>
                              </v-flex>

                              <v-flex xs3 pt-8>
                                <v-layout wrap>
                                  <v-flex
                                    xs12
                                    pb-5
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                    "
                                    >Twitter</v-flex
                                  >
                                  <v-flex
                                    xs12
                                    pb-5
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                    "
                                    >Instagram</v-flex
                                  >

                                  <v-flex
                                    xs12
                                    pb-5
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                    "
                                    >Facebook</v-flex
                                  >

                                  <v-flex
                                    xs12
                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                    "
                                    >For Enquiry +91 &nbsp;7510255897 </v-flex
                                  >
                                
                                </v-layout>
                              </v-flex>

                              <v-flex  xs3 pt-8 pl-lg-5>
                                <v-layout wrap>
                                  <v-flex
                                  @click="$router.push('/TermsAndConditions')"
                                    xs12
                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                      cursor: pointer;
                                    "
                                    >Terms and Conditions </v-flex
                                  >
                                 

                                  <v-flex
                                    xs12
                                    @click="$router.push('/ReturnPolicy')"
                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >ReturnPolicy </v-flex
                                  >
                                 

                                  <v-flex
                                  @click="$router.push('/CancellationPolicy')"
                                    xs12
                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >CancellationPolicy </v-flex
                                  >


                                  <v-flex
                                    xs12
                                    @click="$router.push('/ShippingPolicy')"
                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >ShippingPolicy </v-flex
                                  >
                                 

                                  <v-flex
                                    xs12
                                  @click="$router.push('/RefundPolicy')"

                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >RefundPolicy </v-flex
                                  >

                                  <v-flex
                                  @click="$router.push('/PrivacyPolicy')"
                                    xs12
                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >Privacy Policy </v-flex
                                  >
                                 
                                </v-layout>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout></v-flex
                  >
                  <v-flex xs12>
                    <v-layout
                      fill-height
                      justify-center
                      wrap
                      style="background-color: black; height: 90px"
                    >
                      <v-flex
                        style="
                          font-family: dmsansregular;
                          font-size: 14px;
                          color: white;
                          font-weight: 500;
                        "
                        xs12
                        text-center
                        align-self-center
                      >
                        <span>
                          © Story of 1000 planters 2023 - All rights reserved
                        </span>
                        <span
                          >&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</span
                        >
                        <span
                          >Designed & Developed by
                          <span
                            style="
                              font-family: dmsansregular;
                              font-size: 16px;
                              color: #12aaa3;
                              font-weight: 500;
                            "
                            >Leopard Tech Labs Pvt Ltd</span
                          >
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>

                <v-layout
                  style="background-color: rgba(38, 38, 38, 1)"
                  wrap
                  class="hidden-md-and-up"
                >
                  <v-flex xs12 pt-4 text-center>
                    <div
                      style="
                        font-family: brightfont;
                        font-size: 18px;
                        color: white;
                        font-weight: 400px;
                      "
                    >
                      Story of 1000 planters
                    </div>
                  </v-flex>
                  <v-flex xs12 pt-2 text-center>
                    <div
                      style="
                        font-family: dmsansregular;
                        font-size: 16px;
                        color: white;
                      "
                    >
                      Your go-to online spice emporium, delivering the finest
                      and freshest spices to elevate every dish and inspire your
                      culinary journey.
                    </div>
                  </v-flex>
                  <v-flex xs12 pt-3 text-center>
                    <div
                      style="
                        font-family: dmsansregular;
                        font-size: 14px;
                        color: white;
                        font-weight: 500;
                      "
                    >
                      Shopping cart
                    </div>
                  </v-flex>
                  <v-flex xs12 pt-3 text-center>
                    <div
                      style="
                        font-family: dmsansregular;
                        font-size: 14px;
                        color: white;
                        font-weight: 500;
                      "
                    >
                      Account
                    </div>
                  </v-flex>
                  <v-flex xs12 pt-3 text-center>
                    <div
                      style="
                        font-family: dmsansregular;
                        font-size: 14px;
                        color: white;
                        font-weight: 500;
                      "
                    >
                      Signup
                    </div>
                  </v-flex>
                  <v-flex xs12 pt-3 text-center style="cursor: pointer">
                    <div
                      @click="$router.push('/LoginPage')"
                      style="
                        font-family: dmsansregular;
                        font-size: 14px;
                        color: white;
                        font-weight: 500;
                      "
                    >
                      Login
                    </div>
                  </v-flex>
                  <v-flex xs12 pt-3 text-center>
                    <div
                      style="
                        font-family: dmsansregular;
                        font-size: 14px;
                        color: white;
                        font-weight: 500;
                      "
                    >
                      Twitter
                    </div>
                  </v-flex>
                  <v-flex xs12 pt-3 text-center>
                    <div
                      style="
                        font-family: dmsansregular;
                        font-size: 14px;
                        color: white;
                        font-weight: 500;
                      "
                    >
                      Instagram
                    </div>
                  </v-flex>
                  <v-flex xs12 pt-3 text-center>
                    <div
                      style="
                        font-family: dmsansregular;
                        font-size: 14px;
                        color: white;
                        font-weight: 500;
                      "
                    >
                      Facebook
                    </div>
                  </v-flex>

                  <v-flex  xs12 pt-8 pl-lg-5>
                                <v-layout wrap>
                                  <v-flex
                                  @click="$router.push('/TermsAndConditions')"
                                    xs6
                                    text-center
                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      font-weight: 500;
                                      cursor: pointer;
                                    "
                                    >Terms and Conditions </v-flex
                                  >
                                 

                                  <v-flex
                                  xs6
                                    @click="$router.push('/ReturnPolicy')"
                                    pb-3
                                    text-center
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >ReturnPolicy </v-flex
                                  >
                                 

                                  <v-flex
                                  @click="$router.push('/CancellationPolicy')"
                                  xs6
                                    pb-3
                                    text-center
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >CancellationPolicy </v-flex
                                  >


                                  <v-flex
                                  xs6
                                  text-center
                                    @click="$router.push('/ShippingPolicy')"
                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >ShippingPolicy </v-flex
                                  >
                                 

                                  <v-flex
                                  xs6
                                  text-center
                                  @click="$router.push('/RefundPolicy')"

                                    pb-3
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >RefundPolicy </v-flex
                                  >

                                  <v-flex
                                  @click="$router.push('/PrivacyPolicy')"
                                  xs6
                                    pb-3
                                    text-center
                                    style="
                                      font-family: dmsansregular;
                                      font-size: 14px;
                                      color: white;
                                      cursor: pointer;
                                      font-weight: 500;
                                    "
                                    >Privacy Policy </v-flex
                                  >
                                 
                                </v-layout>
                              </v-flex>
                  <v-flex xs12 pa-4>
                    <v-layout wrap justify-center>
                      <v-flex
                        xs5
                        text-center
                        style="
                          font-family: dmsansregular;
                          font-size: 14px;
                          color: white;
                          font-weight: 500;
                        "
                        >For Enquiry</v-flex
                      >
                      <v-flex
                        xs5
                        text-center
                        style="
                          font-family: dmsansregular;
                          font-size: 14px;
                          color: white;
                          font-weight: 500;
                        "
                        >+91 &nbsp;7510255897</v-flex
                      >
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout
                      fill-height
                      justify-center
                      wrap
                      style="background-color: black; height: 90px"
                    >
                      <v-flex
                        style="
                          font-family: dmsansregular;
                          font-size: 14px;
                          color: white;
                          font-weight: 500;
                        "
                        xs12
                        text-center
                        align-self-center
                      >
                        <span>
                          © Story of 1000 planters 2023 - All rights reserved
                        </span>
                        <span
                          >&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</span
                        >
                        <span
                          >Designed & Developed by
                          <span
                            style="
                              font-family: dmsansregular;
                              font-size: 16px;
                              color: #12aaa3;
                              font-weight: 500;
                            "
                            >Leopard Tech Labs Pvt Ltd</span
                          >
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      currentPage: 1,

      cartAddSuccess: false,
      pages: 0,
      preview: null,
      link: null,
      prev: null,
      page: 1,
      showSnackBar: false,
      msg: null,
      limit: 10,
      url: null,
      categoryName: null,

      appLoading: false,
      name: null,
      description: null,
      duration: null,
      icon: null,
      formData: new FormData(),
      formData2: new FormData(),
      listingOrder: null,
      list: [],
      editdailog: false,
      curid: [],
      searchKeyword: null,
      categoryId: {},
      deletedialog: false,
      editdialog: false,
      noOfDays: null,
      amount: null,
      category: [],
      dialog2: false,
    };
  },
  mounted() {
    this.getList();
    this.getCategory();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    searchKeyword() {
      this.getList();
    },
    status() {
      this.getList();
    },
  },
  methods: {
    getListByCategory(categoryId) {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/store/product/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          categoryId: categoryId,
          page: this.currentPage,
          searchKeyword: this.searchKeyword,
          limit: 12,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    addtoCart(productId) {
      this.appLoading = true;
      this.cartAddSuccess = false;

      axios({
        method: "POST",
        url: "/cart/add",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          productId: productId,
          quantity: this.quantity,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data === true) {
            this.showSnackBar("Product added to cart successfully!");
          }
          location.reload();
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    addtoWishlist(productId) {
      axios({
        method: "POST",
        url: "/wishlist/add/remove",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          productId: productId,
          wishlistType: "Product",
        },
      })
        .then((response) => {
          location.reload();
          if (response.data.status === true) {
            // Wishlist updated successfully, handle any UI changes
          } else {
            console.log("Adding to wishlist failed: " + response.data.msg);
          }
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },

    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/category/list",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.category = response.data.data;
          this.categoryId = this.category.map((item) => item._id);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/store/product/list",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          page: this.currentPage,
          searchKeyword: this.searchKeyword,
          limit: 12,
        },
      })
        .then((response) => {
          this.list = response.data.data;
          this.totalData = response.data.totalLength;
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })

        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
<style scoped>
.bg10 {
  background: var(
    --grend,
    linear-gradient(
      252deg,
      #e4ecee 0%,
      #9bc2c2 0%,
      #6ca6a5 28%,
      #157470 88%,
      #13736f 100%
    )
  );
}
</style>